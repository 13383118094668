import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import localStorage from 'store2';
import loadable from '@loadable/component';

import request from '~utils/request';
import { VIDEO_READY, VIDEO_PLAY, VIDEO_PROGRESS, VIDEO_WATCHED } from '~hooks/useApi';

import { pushDataLayerEvent } from '~utils/data-layer';
import { ACCESS_LEVELS, getGuestTranscriptRedirectProps } from '~utils/required-subscription-plan';
import { POPUPS_MAP, POPUP_CONFIGS } from '~layout/Popups';

import { stringFromUserAccessLevel, calculateAppropriateCtaVideo } from '~utils/playlist-helpers';
import authorizeUser, { getAccessBlockingPopupId } from '~utils/authorization-helper';
import LoadingSpinner from '~components/LoadingSpinner';

import closeFullscreen from '~utils/close-fullscreen';
import { FEATURE_FLAGS, isFeatureEnabled } from '~utils/feature-helpers';
import useConversionFramework from '~hooks/useConversionFramework';
import AnalyticsTracking, { ANALYTIC_EVENT } from '~utils/analytics-tracking';

const IS_MARKETING_SITE = isFeatureEnabled(FEATURE_FLAGS.MARKETING_SITE);

const LOCAL_STORAGE_KEY = 'autoplay';

const PlaylistLayout = loadable(() => import('~components/playlist'), {
  resolveComponent: components => components.PlaylistLayout
});
const MarketingPlaylistLayout = loadable(() => import('~components/playlist'), {
  resolveComponent: components => components.MarketingPlaylistLayout
});

class Playlist extends React.Component {
  constructor(props) {
    super(props);

    const { playlist, session, videoId, promotionalVideos, giftToken, isMarketingSite } = props;

    const { user, isAdmin, isLoggedIn, isPrivilegedMember, isProducer } = session;

    const { subscription_plan_id } = user;

    const userCtaAccessLevel = stringFromUserAccessLevel(subscription_plan_id);

    const adminCtaVideo =
      promotionalVideos && promotionalVideos.find(v => v.accessLevel === 'Admin');

    const userSubscriptionLevelCtaVideo =
      promotionalVideos && promotionalVideos.find(v => v.accessLevel === userCtaAccessLevel);

    const everyoneCtaVideo =
      promotionalVideos && promotionalVideos.find(v => v.accessLevel === 'Everyone');

    const ctaVideo = calculateAppropriateCtaVideo(
      isAdmin,
      adminCtaVideo,
      userSubscriptionLevelCtaVideo,
      everyoneCtaVideo
    );

    const { videos, required_subscription_plan: requiredSubscriptionPlan } = playlist;
    const { isFirst } = ctaVideo || {};

    // FIXME: This garbage doesn't actually checks access for gift tokens
    const { hasAccess, accessBlockingPopupId } =
      !!giftToken || authorizeUser(requiredSubscriptionPlan, session, false, isMarketingSite);

    const currentVideo = videos[videoId];
    const currentVideoIndex = videoId;

    this.state = {
      isPlaying: !!giftToken || hasAccess, // autoplay
      isReachedPercent05: false,
      isReachedPercent10: false,
      isReachedPercent25: false,
      isReachedPercent50: false,
      isReachedPercent75: false,
      isReachedPercent100: false,
      isWatched: false,
      currentVideoIndex,
      currentVideo,
      config: null,
      popup: null,
      hasTimeLeft: true,
      required_subscription_plan: requiredSubscriptionPlan,
      isPopupOpen: true,
      hasUserSufficientAccess: !!giftToken || hasAccess,
      ctaVideo,
      userSubscriptionPlanId: subscription_plan_id,
      isLoggedIn,
      isPrivilegedMember,
      isProducer,
      hasPromotionalVideo: ctaVideo && isFirst,
      isGiftBannerOpen: true,
      isSkipPromotionalButtonOpen: false,
      watched: null,
      accessBlockingPopupId
    };
  }

  toggleVideoPlayState = shouldPlay => {
    this.setState(state => ({ ...state, isPlaying: shouldPlay }));
  };

  showContentBlockingPopup = popupId => {
    const { required_subscription_plan, accessBlockingPopupId } = this.state;
    // if no popup id is provided, we set access blocking popup id as the default popup id
    let popup = popupId || accessBlockingPopupId;
    if (!popup) {
      /*
        if no access blocking popup id is set from the authorize user,
        let's get default access blocking popup id based on the required subscription plan
      */
      popup = getAccessBlockingPopupId(required_subscription_plan);
    }
    this.setState(state => ({ ...state, isPlaying: false, popup, isPopupOpen: true }));
  };

  // GIFT POPUP
  // This is the function that sets the popupID and other things related to popups
  showGiftPopup = () => {
    const { isPrivilegedMember, isLoggedIn, required_subscription_plan, isProducer } = this.state;
    const guestRedirectProps = getGuestTranscriptRedirectProps();
    const isPcContent = required_subscription_plan === 4;

    if (!isLoggedIn && isPcContent) {
      navigate('/patrons-circle', guestRedirectProps);
    } else if (!isLoggedIn) {
      navigate('/subscribe', guestRedirectProps);
    } else if (isPcContent && !isProducer && isLoggedIn) {
      this.setState(state => ({
        ...state,
        isPlaying: false,
        popup: POPUPS_MAP.PRODUCER_CIRCLE_CONTENT_BLOCKER,
        isPopupOpen: true
      }));
    } else if (!isPrivilegedMember && isLoggedIn) {
      this.setState(state => ({
        ...state,
        isPlaying: false,
        popup: POPUPS_MAP.UPGRADE_TO_PREMIUM_FOR_FEATURE,
        isPopupOpen: true
      }));
    } else {
      this.setState(state => ({
        ...state,
        isPlaying: false,
        popup: POPUPS_MAP.SUBSCRIBER_GIFT_CONTENT_FORM,
        isPopupOpen: true
      }));
    }
  };

  handleGiftThankYouClick = () => {
    this.setState(state => ({
      ...state,
      isPlaying: false,
      popup: POPUPS_MAP.GIFT_THANK_YOU,
      isPopupOpen: true,
      isGiftBannerOpen: false
    }));
  };

  handleCloseGiftBanner = () => {
    this.setState(state => ({
      ...state,
      isGiftBannerOpen: false
    }));
  };

  // This method is optimized to minimize the amount of `setState` calls made, hence the amount of explanatory comments.
  showPopup = ({ isAllowed, timeleft, popup }) => {
    if (!timeleft) {
      this.setState(state => ({ ...state, hasTimeLeft: false }));
    }

    if (!isAllowed) {
      // if not allowed, only popup which we could receive is the blocker popup.
      const { required_subscription_plan } = this.state;
      if (required_subscription_plan === ACCESS_LEVELS.UNRESTRICTED) {
        return; // unrestricted content never gets blocked
      }

      this.setState(state => ({ ...state, isPlaying: false, popup, isPopupOpen: true }));
      closeFullscreen();
    } else if (popup) {
      // if we are allowed, but there's still popup, it's a non-blocking marketing popup, always show it.
      this.setState(state => ({ ...state, popup, isPopupOpen: true }));
    }
  };

  setCurrentVideo = video => {
    const { playlist } = this.props;
    navigate(`/video/${playlist?.uid}/${video?.uid}`);
  };

  onReady = () => {
    const { currentVideo } = this.state;
    const { playlist } = this.props;
    const { title, uid: playlistUid } = playlist;
    const { prismicId: videoId, uid: videoUid } = currentVideo;
    request(VIDEO_READY({ videoId }))
      .then(response => {
        // response is time tracking config as defined on the back-end
        this.setState(state => ({ ...state, config: response.data }));
      })
      .then(() => {
        pushDataLayerEvent('video_start', {
          playlistName: title,
          videoName: currentVideo.title
        });
        AnalyticsTracking.trackEvent(ANALYTIC_EVENT.VIDEO_START, {
          'Playlist UID': playlistUid,
          'Playlist Title': title,
          'Video UID': videoUid,
          'Video Title': currentVideo.title
        });
      })
      .catch(console.error); // eslint-disable-line
  };

  onStart = () => {
    const { playlist, session = {}, giftToken, isMarketingSite } = this.props;
    const { prismicId: playlistId, uid: playlistUid } = playlist;
    const { currentVideo, hasUserSufficientAccess, required_subscription_plan } = this.state;
    const { prismicId: videoId } = currentVideo;
    const { user: { has_failed_charges } = {} } = session;

    // Resetting the isPlaying state in order for the showPopup to block correctly
    this.setState(state => ({ ...state, isPlaying: true }));

    if (has_failed_charges) {
      closeFullscreen();
      this.showContentBlockingPopup(POPUPS_MAP.FAILED_CHARGES_BLOCKER);
      return;
    }

    if (has_failed_charges) {
      closeFullscreen();
      this.showContentBlockingPopup(POPUPS_MAP.FAILED_CHARGES_BLOCKER);
      return;
    }

    if (!hasUserSufficientAccess && required_subscription_plan === 4) {
      closeFullscreen();
      this.showContentBlockingPopup(POPUPS_MAP.PRODUCER_CIRCLE_CONTENT_BLOCKER);
      return;
    }

    if (!hasUserSufficientAccess) {
      closeFullscreen();
      this.showContentBlockingPopup();
      return;
    }

    request(VIDEO_PLAY({ videoId, playlistId, playlistUid, giftToken }))
      .then(({ data: timeTrackingData }) => {
        // If frontend is in the mode of "marketing site", no need to show any blockers
        if (isMarketingSite) {
          return;
        }
        this.showPopup(timeTrackingData);
      })
      .catch(console.error); // eslint-disable-line
  };

  onProgress = ({ playedSeconds, played }) => {
    const {
      playlist,
      onProgress: onProgressHook,
      giftToken,
      isMarketingSite,
      allowedSeconds = 90
    } = this.props;
    const { prismicId: playlistId, uid: playlistUid, allowFullWatch } = playlist;

    const {
      isWatched,
      isReachedPercent05,
      isReachedPercent10,
      isReachedPercent25,
      isReachedPercent50,
      isReachedPercent75,
      isReachedPercent100,
      config,
      currentVideo,
      currentVideoIndex,
      watched
    } = this.state;
    const { prismicId: videoId, title, uid: videoUid } = currentVideo;

    if (isMarketingSite) {
      // If frontend is in the mode of "marketing site", people are allowed to watch only the first seconds of the non free content.
      if (!allowFullWatch && playedSeconds > allowedSeconds) {
        this.showContentBlockingPopup(POPUPS_MAP.IN_VIDEO_CONTENT_BLOCKER);
        // track analytics
        this.setState(
          state => ({ ...state, isPlaying: false, isWatched: true }),
          () => {
            pushDataLayerEvent('video_watched', {
              playlistName: title,
              videoName: currentVideo.title
            });
            AnalyticsTracking.trackEvent(ANALYTIC_EVENT.VIDEO_END, {
              'Playlist UID': playlistUid,
              'Playlist Title': title,
              'Video UID': videoUid,
              'Video Title': currentVideo.title
            });
            request(VIDEO_WATCHED({ playlistId, videoId })).catch(console.error); // eslint-disable-line
          }
        );
      }
    } else {
      const seconds = Math.round(playedSeconds);

      // First `onProgress` may and will fire before the config will arrive from the backend.
      // NOTE: DO NOT READ `config` before this guard case!
      if (seconds < 1 || !config) {
        return;
      }

      const watchedSeconds = Math.floor(playedSeconds / config.INTERVAL);
      const isProgressInterval = seconds % (config.INTERVAL / 6) === 0;

      const isTimeToTrackProgress =
        seconds % config.INTERVAL <= 3 && watchedSeconds && watchedSeconds !== watched;

      if (isTimeToTrackProgress) {
        request(
          VIDEO_PROGRESS({ playlistId, playlistUid, videoId, progress: playedSeconds, giftToken })
        )
          .then(({ data: timeTrackingData }) => {
            this.showPopup(timeTrackingData);
          })
          .catch(console.error); // eslint-disable-line

        this.setState(state => ({ ...state, watched: watchedSeconds }));
      }

      if (isProgressInterval) {
        onProgressHook(currentVideoIndex, seconds);
      }

      let progressTracking = null;
      if (played > 0.05 && !isReachedPercent05) {
        progressTracking = { percentage: 5 };
        this.setState(state => ({ ...state, isReachedPercent05: true }));
      } else if (played > 0.1 && !isReachedPercent10) {
        progressTracking = { percentage: 10 };
        this.setState(state => ({ ...state, isReachedPercent10: true }));
      } else if (played > 0.25 && !isReachedPercent25) {
        progressTracking = { percentage: 25 };
        this.setState(state => ({ ...state, isReachedPercent25: true }));
      } else if (played > 0.5 && !isReachedPercent50) {
        progressTracking = { percentage: 50 };
        this.setState(state => ({ ...state, isReachedPercent50: true }));
      } else if (played > 0.75 && !isReachedPercent75) {
        progressTracking = { percentage: 75 };
        this.setState(state => ({ ...state, isReachedPercent75: true }));
      } else if (played > 0.995 && !isReachedPercent100) {
        progressTracking = { percentage: 100 };
        this.setState(state => ({ ...state, isReachedPercent100: true }));
      }

      if (progressTracking) {
        pushDataLayerEvent('video_progress', {
          videoCurrentTime: playedSeconds,
          videoName: title,
          videoPercent: progressTracking.percentage
        });
      }

      const isTimeToTrackWatched = played > config.WATCHED_TRIGGER && !isWatched;
      if (isTimeToTrackWatched) {
        this.setState(
          state => ({ ...state, isWatched: true }),
          () => {
            pushDataLayerEvent('video_watched', {
              playlistName: title,
              videoName: currentVideo.title
            });
            AnalyticsTracking.trackEvent(ANALYTIC_EVENT.VIDEO_END, {
              'Playlist UID': playlistUid,
              'Playlist Title': title,
              'Video UID': videoUid,
              'Video Title': currentVideo.title
            });
            request(VIDEO_WATCHED({ playlistId, videoId })).catch(console.error); // eslint-disable-line
          }
        );
      }
    }
  };

  onEnded = () => {
    const { playlist, onProgress, isMarketingSite } = this.props;
    const { currentVideoIndex, hasPromotionalVideo, ctaVideo, currentVideo } = this.state;
    const { isLast } = ctaVideo || {};
    const { title, prismicId: videoId } = currentVideo;

    const {
      videos,
      nextPlaylist,
      suggestedPlaylists,
      prismicId: playlistId,
      allowFullWatch
    } = playlist;
    const nextVideoIndex = currentVideoIndex + 1;
    const hasNextVideoInPlaylist = !!videos[nextVideoIndex];

    /**
     * When the marketing site is on, let's show the content blocking popup at the end of the last video
     * This would ensure that,
     *  - the free content also would get the blocker at the end of the last video
     *  - the non-free content which are less than 90 seconds also gets the blocker
     */
    if (isMarketingSite) {
      if (allowFullWatch && hasNextVideoInPlaylist) {
        // play the next video in the playlist
        this.setCurrentVideo(videos[nextVideoIndex]);
      } else {
        // last video or only video in the playlist
        this.showContentBlockingPopup(POPUPS_MAP.IN_VIDEO_CONTENT_BLOCKER);
      }
      // track analytics
      this.setState(
        state => ({ ...state, isWatched: true }),
        () => {
          pushDataLayerEvent('video_watched', {
            playlistName: title,
            videoName: currentVideo.title
          });
          request(VIDEO_WATCHED({ playlistId, videoId })).catch(console.error);
        }
      );

      // we don't want rest of the code (autoplay) for the marketing site. so let's just return the function here.
      return;
    }

    // Non marketing site next video autoplay logic
    const autoplayState = localStorage.get(LOCAL_STORAGE_KEY);

    if (autoplayState && hasNextVideoInPlaylist) {
      this.setCurrentVideo(videos[nextVideoIndex]);
    } else if (!hasNextVideoInPlaylist && isLast) {
      setTimeout(() => {
        this.setState(state => ({ ...state, hasPromotionalVideo: true }));
      }, 3000);
    } else if (autoplayState && nextPlaylist && !hasPromotionalVideo) {
      onProgress(0, 0);
      navigate(nextPlaylist.url);
    } else if (
      autoplayState &&
      suggestedPlaylists &&
      suggestedPlaylists.length > 0 &&
      !hasPromotionalVideo
    ) {
      onProgress(0, 0);
      navigate(suggestedPlaylists[0].url);
    } else {
      // nothing to play, just stop
    }
  };

  onPromotionEnded = () => {
    const { playlist, onProgress } = this.props;
    const { ctaVideo } = this.state;
    const { isLast } = ctaVideo;

    const { nextPlaylist, suggestedPlaylists } = playlist;
    const autoplayState = localStorage.get(LOCAL_STORAGE_KEY);

    this.setState(state => ({
      ...state,
      hasPromotionalVideo: false,
      isSkipPromotionalButtonOpen: false,
      isPlaying: autoplayState
    }));

    if (autoplayState && isLast && nextPlaylist) {
      onProgress(0, 0);
      navigate(nextPlaylist.url);
    } else if (autoplayState && suggestedPlaylists && suggestedPlaylists.length > 0 && isLast) {
      onProgress(0, 0);
      navigate(suggestedPlaylists[0].url);
    } else {
      // nothing to play, just stop
    }
  };

  handleBlockingPopupClose = () => {
    const { popup } = this.state;
    // If the user is closing the popup which is a full blocker
    // we redirect them to the home page so they cant watch anymore
    if (POPUP_CONFIGS.redirectHomeOnClose.includes(popup)) {
      navigate('/');
    }
    const popupConfig = POPUP_CONFIGS[popup];
    const updatedState = { isPopupOpen: false, popup: null };
    if (popupConfig?.autoplayOnClose) {
      updatedState.isPlaying = true;
    }
    this.setState(state => ({ ...state, ...updatedState }));
  };

  onPromotionalProgress = ({ playedSeconds }) => {
    const seconds = Math.round(playedSeconds);

    if (seconds >= 4) {
      this.setState(state => ({ ...state, isSkipPromotionalButtonOpen: true }));
    }
  };

  render() {
    const {
      session,
      seekToSecond,
      playlist,
      hasPodcastSubscriptionLink,
      promotionalVideos,
      labels,
      senderFullName,
      giftToken,
      isMarketingSite
    } = this.props;
    const {
      currentVideo,
      currentVideoIndex,
      hasTimeLeft,
      isPlaying,
      popup,
      isPopupOpen,
      hasUserSufficientAccess,
      hasPromotionalVideo,
      ctaVideo,
      userSubscriptionPlanId,
      required_subscription_plan,
      isGiftBannerOpen,
      isSkipPromotionalButtonOpen
    } = this.state;

    const { promoVideoUrl } = ctaVideo || {};

    const { isSubscriber, isPrivilegedMember, isAdmin, isProducer, user } = session;

    const { subscription_plan_id } = user;

    const isNotAllowedToComment = !hasTimeLeft && !isSubscriber && !isAdmin;

    const {
      prismicId,
      uid,
      title,
      author,
      videos,
      nextPlaylist,
      suggestedPlaylists,
      trendingPlaylists,
      popularPlaylists,
      materials
    } = playlist;

    if (userSubscriptionPlanId !== subscription_plan_id) {
      const userCtaAccessLevel = stringFromUserAccessLevel(subscription_plan_id);

      const adminCtaVideo =
        promotionalVideos && promotionalVideos.find(v => v.accessLevel === 'Admin');

      const userSubscriptionLevelCtaVideo =
        promotionalVideos && promotionalVideos.find(v => v.accessLevel === userCtaAccessLevel);

      const everyoneCtaVideo =
        promotionalVideos && promotionalVideos.find(v => v.accessLevel === 'Everyone');

      const ctaVideoRender = calculateAppropriateCtaVideo(
        isAdmin,
        adminCtaVideo,
        userSubscriptionLevelCtaVideo,
        everyoneCtaVideo
      );

      const { isFirst } = ctaVideoRender || {};

      this.setState(state => ({
        ...state,
        ctaVideo: ctaVideoRender,
        hasPromotionalVideo: ctaVideo && isFirst,
        userSubscriptionPlanId: subscription_plan_id
      }));
    }

    if (
      (isPrivilegedMember || isAdmin) &&
      !hasUserSufficientAccess &&
      required_subscription_plan !== 4
    ) {
      this.setState(state => ({ ...state, isPlaying: true, hasUserSufficientAccess: true }));
    }

    if ((isProducer || isAdmin) && !hasUserSufficientAccess && required_subscription_plan === 4) {
      this.setState(state => ({ ...state, isPlaying: true, hasUserSufficientAccess: true }));
    }

    /*
     * If user watched "almost up to the end" of the video (less than 60 seconds left to view),
     * we reset the position back to 0. Because otherwise, we risk skipping the video completely and
     * auto-playing into the next one --- actual loading of the video can take more than 60 seconds,
     * *and the player timer will be ticking all that time!*
     * Also a fallback for the case of wrong data: if we don't know the length of the video,
     * don't "continue watching" at all, always reset instead.
     */
    const continueWatchingPosition =
      isMarketingSite || // when marketing site is enabled, videos should start from the beginning always
      !Number(currentVideo.length_in_seconds) ||
      currentVideo.length_in_seconds - seekToSecond < 60
        ? 0
        : seekToSecond;

    if (isMarketingSite) {
      return (
        <MarketingPlaylistLayout
          isPlaying={isPlaying}
          seekToSecond={continueWatchingPosition}
          currentVideo={currentVideo}
          currentVideoIndex={currentVideoIndex}
          numberOfVideos={playlist.videos.length}
          handlers={{
            onReady: this.onReady,
            onStart: this.onStart,
            onProgress: this.onProgress,
            onEnded: this.onEnded,
            handleBlockingPopupClose: this.handleBlockingPopupClose,
            showContentBlockingPopup: this.showContentBlockingPopup,
            setCurrentVideo: this.setCurrentVideo,
            onPromotionalProgress: this.onPromotionalProgress,
            onPromotionEnded: this.onPromotionEnded,
            showGiftPopup: this.showGiftPopup,
            handleGiftThankYouClick: this.handleGiftThankYouClick,
            handleCloseGiftBanner: this.handleCloseGiftBanner,
            toggleVideoPlayState: this.toggleVideoPlayState
          }}
          popup={popup}
          isPopupOpen={isPopupOpen}
          title={title}
          author={author}
          nextPlaylist={nextPlaylist}
          prismicId={prismicId}
          uid={uid}
          hasUserSufficientAccess={false}
          suggestedPlaylists={suggestedPlaylists}
          trendingPlaylists={trendingPlaylists}
          popularPlaylists={popularPlaylists}
          hasPromotionalVideo={hasPromotionalVideo}
          promoVideoId={promoVideoUrl}
          labels={labels}
          thumbnail={playlist.firstVideo.cover_image}
          isSingleVideo={playlist.videos.length === 1}
          senderFullName={senderFullName}
          giftToken={giftToken}
          isGiftPlaylist={giftToken && senderFullName}
          isGiftBannerOpen={isGiftBannerOpen}
          isSkipPromotionalButtonOpen={isSkipPromotionalButtonOpen}
        />
      );
    }

    return (
      <PlaylistLayout
        isPlaying={isPlaying}
        seekToSecond={continueWatchingPosition}
        currentVideo={currentVideo}
        handlers={{
          onReady: this.onReady,
          onStart: this.onStart,
          onProgress: this.onProgress,
          onEnded: this.onEnded,
          handleBlockingPopupClose: this.handleBlockingPopupClose,
          showContentBlockingPopup: this.showContentBlockingPopup,
          setCurrentVideo: this.setCurrentVideo,
          onPromotionalProgress: this.onPromotionalProgress,
          onPromotionEnded: this.onPromotionEnded,
          showGiftPopup: this.showGiftPopup,
          handleGiftThankYouClick: this.handleGiftThankYouClick,
          handleCloseGiftBanner: this.handleCloseGiftBanner,
          toggleVideoPlayState: this.toggleVideoPlayState
        }}
        popup={popup}
        isPopupOpen={isPopupOpen}
        title={title}
        hasPodcastSubscriptionLink={hasPodcastSubscriptionLink}
        author={author}
        materials={materials}
        currentVideoIndex={currentVideoIndex}
        hasUserSufficientAccess={hasUserSufficientAccess}
        videos={videos}
        nextPlaylist={nextPlaylist}
        prismicId={prismicId}
        uid={uid}
        isNotAllowedToComment={isNotAllowedToComment}
        suggestedPlaylists={suggestedPlaylists}
        hasPromotionalVideo={hasPromotionalVideo}
        promoVideoId={promoVideoUrl}
        labels={labels}
        thumbnail={playlist.firstVideo.cover_image}
        isSingleVideo={playlist.videos.length === 1}
        senderFullName={senderFullName}
        giftToken={giftToken}
        isGiftPlaylist={giftToken && senderFullName}
        isGiftBannerOpen={isGiftBannerOpen}
        isSkipPromotionalButtonOpen={isSkipPromotionalButtonOpen}
        playlistUid={playlist.uid}
      />
    );
  }
}

// Below HOC has been added to make the best use of functional components
// When Playlist class based component gets rewritten to a functional component,
// we can easily move the logic to the Playlist component it self.
const withPlaylistWrapper = Component => props => {
  const { isMarketingSite } = useConversionFramework();

  if (isMarketingSite === null) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  return <Component {...props} isMarketingSite={isMarketingSite} />;
};

Playlist.propTypes = {
  playlist: PropTypes.shape({
    title: PropTypes.string,
    uid: PropTypes.string,
    author: PropTypes.shape({}),
    materials: PropTypes.arrayOf(PropTypes.shape({})),
    prismicId: PropTypes.string,
    videos: PropTypes.arrayOf(
      PropTypes.shape({
        uid: PropTypes.string
      })
    ),
    required_subscription_plan: PropTypes.number.isRequired,
    allowFullWatch: PropTypes.bool,
    nextPlaylist: PropTypes.shape({
      localFile: PropTypes.shape({}).isRequired,
      url: PropTypes.string
    }),
    suggestedPlaylists: PropTypes.arrayOf(
      PropTypes.shape({
        localFile: PropTypes.shape({}).isRequired,
        url: PropTypes.string
      })
    ),
    trendingPlaylists: PropTypes.arrayOf(
      PropTypes.shape({
        localFile: PropTypes.shape({}).isRequired,
        url: PropTypes.string
      })
    ),
    popularPlaylists: PropTypes.arrayOf(
      PropTypes.shape({
        localFile: PropTypes.shape({}).isRequired,
        url: PropTypes.string
      })
    ),
    firstVideo: PropTypes.shape({
      cover_image: PropTypes.shape({
        localFile: PropTypes.shape({}).isRequired,
        alt: PropTypes.string,
        url: PropTypes.string
      }).isRequired
    }).isRequired
  }).isRequired,

  session: PropTypes.shape({
    isSubscriber: PropTypes.bool,
    isPrivilegedMember: PropTypes.bool,
    isProducer: PropTypes.bool,
    isAdmin: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    user: PropTypes.shape({
      subscription_plan_id: PropTypes.number
    })
  }),
  videoId: PropTypes.number,
  seekToSecond: PropTypes.number,
  hasPodcastSubscriptionLink: PropTypes.bool,
  onProgress: PropTypes.func,
  promotionalVideos: PropTypes.arrayOf(
    PropTypes.shape({
      promoVideoUrl: PropTypes.string.isRequired,
      accessLevel: PropTypes.string.isRequired,
      isFirst: PropTypes.bool.isRequired,
      isLast: PropTypes.bool.isRequired
    })
  ),
  labels: PropTypes.shape({
    isAudio: PropTypes.bool.isRequired,
    isNew: PropTypes.bool.isRequired,
    isPremium: PropTypes.bool.isRequired,
    isProducer: PropTypes.bool.isRequired
  }).isRequired,
  giftToken: PropTypes.string,
  senderFullName: PropTypes.string,
  isMarketingSite: PropTypes.bool,
  allowedSeconds: PropTypes.number
};

Playlist.defaultProps = {
  session: { user: {} },
  videoId: undefined,
  seekToSecond: undefined,
  hasPodcastSubscriptionLink: false,
  giftToken: undefined,
  senderFullName: undefined,
  onProgress: () => {},
  promotionalVideos: [],
  isMarketingSite: false,
  allowedSeconds: 90 // default allowed seconds for the marketing site
};

export default !IS_MARKETING_SITE ? Playlist : withPlaylistWrapper(Playlist);
